import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import Layout from "../components/layout"
import Head from "../components/head"

const BlogPage = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulBlogPost(sort: { fields: publishedDate, order: DESC }) {
        edges {
          node {
            title
            slug
            publishedDate(formatString: "MMMM Do, YYYY")
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <section className="blog__container">
        <Head title="Blog" />
        <div className="blog__box">
          <h1 className="blog__title">Latest Blogs</h1>
          <ol className="blog__posts">
            {data.allContentfulBlogPost.edges.map(edge => {
              return (
                <li className="blog__post" key={edge.node.publishedDate}>
                  <Link
                    className="blog__post--link"
                    to={`/blog/${edge.node.slug}`}
                  >
                    <h4 className="post__title">{edge.node.title}</h4>
                    <p className="post__date">{edge.node.publishedDate}</p>
                  </Link>
                </li>
              )
            })}
          </ol>
        </div>
      </section>
    </Layout>
  )
}

export default BlogPage

// const data = useStaticQuery(graphql`
//   query {
//     allContentfulBlogPost(sort: { fields: publishedDate, order: DESC }) {
//       edges {
//         node {
//           title
//           slug
//           publishedDate(formatString: "MMMM Do, YYYY")
//         }
//       }
//     }
//   }
// `)

// {data.allContentfulBlogPost.edges.map(edge => {
//   return (
//     <li className={blogStyles.post} key={edge.node.publishedDate}>
//       <Link to={`/blog/${edge.node.slug}`}>
//         <h2>{edge.node.title}</h2>
//         <p>{edge.node.publishedDate}</p>
//       </Link>
//     </li>
//   )
// })}
